import React, {useEffect, useState} from "react";
import {AxiosError} from "axios";
import {Button, Container, Spinner} from "react-bootstrap";
import SuccesfulConnection from "../components/SuccessfulConnection";
import Footer from "../components/Footer";
import PaymentForm from "../components/PaymentForm";
import LogoCircles from "../components/LogoCircles";
import mollieLogo from "../Mollie.svg";
import {JwtUtil} from "../util/jwt-util";
import axiosClient from "../AxiosConfig";
import {IPaymentLink, PaymentStatus} from "../model/IPaymentLink";


function PaymentRedirectHandler() {
    /* Get uuid from path */
    const [paymentLink, setPaymentLink] = useState<IPaymentLink | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>("");
    const [website, setWebsite] = useState<string | null>("");

    useEffect(() => {
        // Wait a couple of seconds before fetching the payment link
        setIsLoading(true);

        setTimeout(() => {

            console.log("Fetching payment link");
            fetchPaymentLink();
        }, 3000);

        async function fetchPaymentLink() {
            try {
                // Get the uuid from the path
                const path = window.location.pathname;
                // It's the last part of the path
                const uuid = path.split("/").pop();
                const response = await axiosClient.get(`payment-links/${uuid}`);
                const data: IPaymentLink = response.data;
                setPaymentLink(data);
            } catch (e) {
                if (e instanceof AxiosError && e.response) {
                    if (e.response.status === 404) {
                        setError('Payment link not found');
                    } else if (e.response?.data?.message) {
                        if (JwtUtil.checkJWT()) {
                            setError(e.response.data.message);
                        } else {
                            setWebsite(e.response.data.website);
                            setError('Something went wrong while generating the payment link, please contact the merchant. If you are the merchant, please login to see more details.');
                        }
                    }
                }

                console.error("Error fetching payment link:", e);
            } finally {
                setIsLoading(false);
            }

        }

    }, []);

    function getErrorWithWebsite() {
        if (website !== null || website !== "")
            return <p>Something went wrong while generating the payment link, please contact <a
                href={website?.toString()}>{website}</a> for more details.</p>;
    }

    function tryAgainButtonHandler(paymentLink: IPaymentLink | null) {
        return () => {
            console.log('Redirecting to payment link', paymentLink?.checkoutUrl);
            window.location.href = paymentLink?.checkoutUrl as string
        };
    }

    function renderPaymentLink(paymentLink: IPaymentLink | null) {
        if (website) {
            return <div className="App-header payment-header">
                <SuccesfulConnection success={false}
                                     element={getErrorWithWebsite()}/>
            </div>
        }
        if (!paymentLink || error) {
            return <div className="App-header payment-header">
                <SuccesfulConnection message={error ? error : 'Payment link not found'} success={false}/>
            </div>
        }

        if (paymentLink.status === PaymentStatus.Refunded ||
            paymentLink.status === PaymentStatus.Partially_Refunded) {
            return <div className="App-header payment-header">
                <SuccesfulConnection message="This payment was refunded! " success={true}/>
            </div>
        }

        if (paymentLink.status === PaymentStatus.Paid ||
            paymentLink.status === PaymentStatus.Refund_Pending) {
            return <>
                <PaymentForm amount={paymentLink.amount} currencyCode={paymentLink.currency}
                             website={paymentLink?.website}/>
                {!JwtUtil.checkJWT() && <Footer/>}
            </>
        }

        if (paymentLink.status === PaymentStatus.Failed) {
            console.log('Payment link failed', paymentLink);
            return <>
                <div className="App-header payment-header">
                    <SuccesfulConnection message="Payment Failed" success={false}/>
                    <Container>
                        <Button variant={"primary"} onClick={tryAgainButtonHandler(paymentLink)}>Try again</Button>
                    </Container>
                </div>
            </>
        }

        if (paymentLink.status === PaymentStatus.Generated) {

            setTimeout(function () {
                // Redirect to Mollie checkout
                window.location.href = paymentLink.checkoutUrl!;
            }, 3000)
            return <>
                <div className="App-header payment-header">
                    <SuccesfulConnection message="Redirecting you to Mollie Checkout" success={true}/>
                </div>
            </>
        }
    }

    return (
        <>
            {isLoading ? (<div className="App-header payment-header">
                    <div className="logo__wrapper">
                        <img src={mollieLogo} className="Mollie-logo" alt="logo"/>
                        <LogoCircles numberOfCircles={5}/>
                    </div>
                    <Spinner style={{marginTop: 149}} variant="light"/>
                </div>
            ) : (
                renderPaymentLink(paymentLink)
            )}
        </>
    );
}

export default PaymentRedirectHandler;
